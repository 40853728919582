'use client';

import React, {
  Context,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import NextImage from 'next/image';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';

import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useMedia } from 'react-use';

import { LoaderCircle, Mail } from 'lucide-react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Dialog, DialogContent } from 'components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Textarea } from 'components/ui/textarea';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { ArrowLeftIcon, KeyIcon, MailIcon } from 'components/icons/v2';
import { Sheet, SheetContent } from 'components/ui/sheet';
import { Alert } from 'components/ui/alert';
import { InfoIcon } from 'components/icons';

import { MEMBER_ROUTES, OWNER_ROUTES } from 'utils/routes';

import {
  ApplicationFieldNode, CommunityCommunityJoinPolicyChoices,
  LandingBenefitsInput,
  OAuthLink,
  RoleEnum,
  UsersYkmeUserInviteStatusChoices
} from 'generated/types';
import { useLandingApplyMutation, useLoginUserMutation, useResetPasswordMutation } from 'generated/hooks';
import { useFormErrorHandling } from 'hooks/useFormErrorHandling';
import { useCommunityLandingPageTracking, useJoinCommunityFormTracking } from 'app/_tracking/events';
import { TERMS_OF_SERVICE_LINK } from 'app/_data';
import { useAlerts } from './alertContext';
import { GetOAuthButton } from '../(unauthenticated)/_utils/oauth';

import { ILandingConfig } from 'types';

interface ILandingProvider {
  appConfig: ILandingConfig;
  children: ReactNode;
  communityName: string;
  landingFields: ApplicationFieldNode[];
  logo?: string | null;
  oAuthLinks: OAuthLink[];
  subdomain: string;
  telegramBotHandle?: string | null;
  telegramAuthUrl?: string | null;
  joinPolicy: CommunityCommunityJoinPolicyChoices;
}

export enum ModalTabs {
  LOGIN = 'login',
  APPLY = 'apply'
}

enum ForgotPasswordState {
  NOT_SEND = 'not_send',
  SENT = 'sent'
}

interface ILanding {
  currentTab?: ModalTabs;
  setTab: Dispatch<SetStateAction<ModalTabs | undefined>>;
}

const defaultValues: ILanding = {
  currentTab: undefined,
  setTab: () => void 0
};

const LandingContext: Context<ILanding> = createContext(defaultValues);

interface ILoginForm {
  email: string;
  password: string;
}

interface IResetPasswordForm {
  email: string;
}

const loginFormSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().min(1, 'Password cannot be empty')
});

const resetPasswordFormSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
});


export const LandingProvider: FC<ILandingProvider> = (props) => {
  const {
    appConfig,
    children,
    communityName,
    landingFields,
    logo,
    oAuthLinks,
    subdomain,
    joinPolicy
  } = props;

  const isMobile = useMedia('(max-width: 768px)', false);

  const { trackCommunityLandingPage } = useCommunityLandingPageTracking();

  useEffect(() => {
    trackCommunityLandingPage();
  }, []);

  const [currentTab, setTab] = useState<ModalTabs | undefined>();
  const [forgotPasswordState, setForgotPasswordState] = useState<ForgotPasswordState | undefined>();
  const [socialError, setSocialError] = useState<string>('');

  const params = useSearchParams();
  const router = useRouter();

  const [loginState, loginMutation] = useLoginUserMutation();
  const [applicationFieldsState, applyLanding] = useLandingApplyMutation();
  const [resetPasswordState, resetPassword] = useResetPasswordMutation();

  const { handleErrors } = useFormErrorHandling();
  const { trackJoinCommunityForm } = useJoinCommunityFormTracking();

  const { error: errorAlert } = useAlerts();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError
  } = useForm<ILoginForm>({
    mode: 'all',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const {
    handleSubmit: handleSubmitApplicationForm,
    control: controlApplicationForm,
    reset: resetApplicationForm,
    formState: { errors: applicationFormErrors },
    setError: setApplicationFormError
  } = useForm({
    mode: 'all'
  });

  const {
    handleSubmit: handleSubmitResetPasswordForm,
    control: controlResetPasswordForm,
    reset: resetResetPasswordForm,
    formState: { errors: resetPasswordFormErrors },
    setError: setResetPasswordFormError,
    getValues: getResetPasswordFormValues,
    clearErrors: clearResetPasswordFormErrors
  } = useForm<IResetPasswordForm>({
    mode: 'all',
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      email: ''
    }
  });

  const value: ILanding = useMemo(() => ({
    currentTab,
    setTab
  }), [currentTab]);

  const [isApplicationSend, setApplicationSend] = useState<string | undefined>();

  const onSubmitLogin = useCallback(async (data: any) => {
    try {
      const vars = {
        email: data.email,
        password: data.password
      };
      const context = {
        fetchOptions: { headers: { 'X-Community-Subdomain': subdomain } }
      };
      const resp = await loginMutation(vars, context);

      if (resp.error) {
        return handleErrors<ILoginForm>(resp.error, setError, { username: 'email' });
      }

      if (!resp.data?.login?.user?.communityMember || resp.data.login.user.communityMember.role === RoleEnum.Member) {
        return router.push(MEMBER_ROUTES.MEMBERS.getPath());
      }

      if (resp.data?.login?.user?.inviteStatus === UsersYkmeUserInviteStatusChoices.Pending) {
        return router.push(`${OWNER_ROUTES.PENDING.getPath()}`);
      }

      if (!resp.data?.login?.community?.id) {
        return router.push(OWNER_ROUTES.START.getPath());
      }

      if (resp.data?.login?.user?.id && resp.data?.login?.community) {
        let next = OWNER_ROUTES.PANEL.getPath();
        if (params?.get('redirect')) {
          next = params?.get('redirect') || '';
        }

        router.push(next);
      }
    } catch (error) {
      console.error(error);
    }
  }, [subdomain, loginMutation, handleErrors, setError, router, params]);

  const handleCloseLoginModal = useCallback(() => {
    setSocialError(() => '');
    setTab(() => undefined);
  }, []);

  const onSubmitApplicationForm = useCallback(async (data: any) => {
    try {
      trackJoinCommunityForm();

      const formValues = landingFields.map((item) => {
        return {
          fieldId: item.name ?? '',
          value: data[item.name as string] ?? '',
          fieldLabel: item.label ?? ''
        };
      });

      const response = await applyLanding({
        values: formValues
      });

      if (response.error) {
        errorAlert('Oops! Something went wrong!');
        return handleErrors<LandingBenefitsInput>(response.error, setApplicationFormError, {});
      }

      if (response.data?.landingApply?.inviteLink) {
        window.location.href = response.data.landingApply.inviteLink;
      }

      handleCloseLoginModal();

      const key = Object.keys(data).find(key => key.includes('email'));

      if (key) {
        setApplicationSend(() => data[key]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [applyLanding, errorAlert, handleCloseLoginModal, handleErrors, landingFields, setApplicationFormError, trackJoinCommunityForm]);

  const onResetPasswordSubmit = useCallback(async (data: IResetPasswordForm) => {
    try {
      const response = await resetPassword({
        email: data.email
      });

      if (response.error) {
        return handleErrors<IResetPasswordForm>(response.error, setResetPasswordFormError, {});
      }

      setForgotPasswordState(() => ForgotPasswordState.SENT);
    } catch (error) {
      console.error(error);
    }
  }, [handleErrors, resetPassword, setResetPasswordFormError]);

  const resetPasswordEmailValue = useWatch({
    control: controlResetPasswordForm,
    name: 'email'
  });

  useEffect(() => {
    clearResetPasswordFormErrors('root');
  }, [clearResetPasswordFormErrors, resetPasswordEmailValue]);

  useEffect(() => {
    reset();
    resetApplicationForm();
    resetResetPasswordForm();
  }, [currentTab, reset, resetApplicationForm, resetResetPasswordForm]);

  useEffect(() => {
    if (params?.size) {
      let restParams = params.toString();

      if (!!params?.get('socialError') && params?.get('socialError') === 'not_admin') {
        setSocialError(() => params.get('socialError') ?? '');

        restParams = restParams.replace('socialError=not_admin', '');
      }

      if (!!params?.get('login')) {
        setTab(() => ModalTabs.LOGIN);
        restParams = restParams.replace('login=true', '');
      }

      router.replace(`${OWNER_ROUTES.HOME.getPath()}?${restParams}`);
    }
  }, [params, router]);

  const DialogRoot = isMobile ? Sheet : Dialog;
  const Content = isMobile ? SheetContent : DialogContent;

  const applicationFormSubmitButtonTitle = useMemo(() => {
    switch (joinPolicy) {
      case 'AFTER_APPROVE':
        return 'Submit';
      case 'AFTER_APPLY':
        return 'Join';
      default:
        return 'Finish & Send'
    }
  }, [joinPolicy]);

  return (
    <LandingContext.Provider value={value}>
      {children}
      <DialogRoot open={!!currentTab} onOpenChange={handleCloseLoginModal}>
        <Content
          showClose
          className="p-0 bg-white md:!max-w-full md:!w-full md:h-full"
          side="top"
        >
          <div className="p-6xl md:p-3 sm:p-0">
            <div className="px-4xl flex flex-col gap-4xl md:p-4">
              <div className="flex flex-col gap-3xl">
                <div className="flex gap-2 self-center items-center">
                  {!!logo && (
                    <NextImage src={logo} width={41} height={41} alt="Community-logo" />
                  )}
                </div>
                <div className="flex flex-col gap-lg items-center">
                  <p className="text-black text-display-sm font-semibold">{communityName}</p>
                  {currentTab === ModalTabs.APPLY && (
                    <p className="text-gray-400 text-medium">Fill in the Application</p>
                  )}
                </div>
                <div>
                  <Tabs defaultValue={currentTab} value={currentTab}
                        onValueChange={(value) => setTab(() => value as ModalTabs)}>
                    <TabsContent value={ModalTabs.APPLY}>
                      <div className="pt-4xl flex flex-col gap-6">
                        <form className="flex flex-col gap-6"
                              onSubmit={handleSubmitApplicationForm(onSubmitApplicationForm)}>
                          {landingFields.map((field, index) => field.type === 'TEXTAREA' ? (
                            <Controller
                              key={index}
                              name={field.name}
                              control={controlApplicationForm}
                              rules={{ required: field.required ? 'This field is required' : false }}
                              render={(props) => (
                                <Textarea
                                  required={field.required}
                                  label={field.label}
                                  placeholder={field.placeholder ?? ''}
                                  errorText={applicationFormErrors[field.name as string]?.message as string}
                                  rows={3}
                                  {...props.field}
                                />
                              )}
                            />
                          ) : field.type === 'PHONE' ? (
                            <Controller
                              key={index}
                              name={field.name ?? ''}
                              control={controlApplicationForm}
                              render={(props) => (
                                <PhoneInput
                                  placeholder={field.placeholder ?? ''}
                                  containerClass="w-full"
                                  inputClass="!w-full !h-[48px] !border !border-slate-300 !rounded-lg"
                                  buttonClass="!rounded-l-lg"
                                  country="us"
                                  {...props.field}
                                />
                              )}
                            />
                          ) : (
                            <Controller
                              key={index}
                              name={field.name}
                              control={controlApplicationForm}
                              rules={{ required: field.required ? 'This field is required' : false }}
                              render={(props) => (
                                <Input
                                  type={field.type.toLowerCase()}
                                  required={field.required}
                                  label={field.label}
                                  placeholder={field.placeholder ?? ''}
                                  errorText={applicationFormErrors[field.name as string]?.message as string}
                                  {...props.field}
                                />
                              )}
                            />
                          ))}
                          {!!applicationFormErrors.root && (
                            <p
                              className={`text-small text-error-600`}>{applicationFormErrors.root.message}</p>
                          )}
                          <div className="flex flex-col gap-3">
                            <Button
                              disabled={applicationFieldsState.fetching}
                              type="submit"
                              size="large"
                              variant="primaryBrand"
                            >
                              {applicationFieldsState.fetching && <LoaderCircle className="animate-spin" />}
                              {applicationFormSubmitButtonTitle}
                            </Button>
                            <div className="flex flex-col justify-center">
                              <p className="text-[12px]/[18px] text-gray-600 text-center">
                                By pressing &lsquo;{applicationFormSubmitButtonTitle}&rsquo;, you agree to the
                              </p>
                              <Button
                                className="p-0"
                                variant="nakedBrandLink"
                                type="button"
                                onClick={(event) => {
                                  event.preventDefault();
                                  window.open(TERMS_OF_SERVICE_LINK, '_blank');
                                }}
                              >
                                Terms and Conditions.
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </TabsContent>
                    <TabsContent value={ModalTabs.LOGIN}>
                      <div className="pt-4xl flex flex-col gap-6">
                        <div className="flex flex-col gap-3">
                          {socialError === 'not_admin' && (
                            <div className="mt-3">
                              <Alert className="bg-red-light text-red-600">
                                <div className="flex gap-3 items-center">
                                  <InfoIcon />
                                  No permissions to access community
                                </div>
                              </Alert>
                            </div>
                          )}
                          {oAuthLinks?.map((link, index) => (
                            <GetOAuthButton link={link} key={index} />
                          ))}
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="flex-1 border-b border-b-gray-200" />
                          <span className="text-medium text-gray-600">OR</span>
                          <div className="flex-1 border-b border-b-gray-200" />
                        </div>
                        <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmitLogin)}>
                          <Controller
                            control={control}
                            name="email"
                            render={({ field, formState: { errors } }) => (
                              <Input
                                label="Email"
                                placeholder="Enter your email"
                                errorText={errors.email?.message as string}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="password"
                            render={({ field }) => (
                              <Input
                                label="Password"
                                type="password"
                                placeholder="••••••••"
                                errorText={errors.password?.message as string}
                                {...field}
                              />
                            )}
                          />
                          {!!errors.root && (
                            <p
                              className={`text-small text-error-600`}>{errors.root.message}</p>
                          )}
                          <div className="flex justify-end">
                            <Button
                              type="button"
                              variant="nakedGrayLink"
                              className="p-0 text-small"
                              onClick={() => {
                                handleCloseLoginModal();
                                setForgotPasswordState(() => ForgotPasswordState.NOT_SEND);
                              }}
                            >
                              Forgot password
                            </Button>
                          </div>
                          <Button
                            disabled={loginState.fetching}
                            type="submit"
                            variant={appConfig.buttonVariant}
                            size="large"
                          >
                            {loginState.fetching && <LoaderCircle className="animate-spin" />}
                            Sign in
                          </Button>
                        </form>
                        <div className="flex justify-center items-center gap-1">
                          <p className="text-sm text-gray-600">Don’t have an account?</p>
                          <Button
                            className={`p-0 text-sm ${appConfig.joinButton}`}
                            size="medium"
                            variant="nakedGrayLink"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push(OWNER_ROUTES.REGISTER.getPath());
                            }}
                          >
                            Sign up
                          </Button>
                        </div>
                      </div>
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </DialogRoot>
      <Dialog open={!!isApplicationSend} onOpenChange={() => setApplicationSend(() => undefined)}>
        <DialogContent className="p-0 sm-1/2:max-w-full sm-1/2:w-full">
          <div className="p-6xl md:p-3 sm:p-0">
            <div className="flex flex-col gap-8 items-center text-gray-900">
              <div className="flex justify-center items-center border border-gray-200 gap-3 w-14 h-14 rounded-xl">
                <Mail width={28} height={28} />
              </div>
              <p className="text-display-sm font-semibold">Check your email</p>
              <div>
                <p className="text-medium text-gray-600">We sent a verification link to</p>
                <Link href={`mailto:${isApplicationSend}`}>{isApplicationSend}</Link>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {!!forgotPasswordState && (
        <Dialog open onOpenChange={() => setForgotPasswordState(() => undefined)}>
          <DialogContent className="p-0 sm-1/2:max-w-full sm-1/2:w-full">
            <div className="p-6xl md:p-8">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-6 items-center text-gray-900">
                  <div
                    className="flex justify-center items-center border border-gray-200 gap-3 w-14 h-14 rounded-xl text-gray-800">
                    {forgotPasswordState === ForgotPasswordState.NOT_SEND ? (
                      <KeyIcon width={28} height={28} />
                    ) : (
                      <MailIcon width={28} height={28} />
                    )}
                  </div>
                  <div className="flex flex-col gap-3 items-center">
                    <p className="text-display-sm font-semibold">
                      {forgotPasswordState === ForgotPasswordState.NOT_SEND ? 'Forgot password?' : 'Check your email'}
                    </p>
                    <p className="text-gray-600 text-center">
                      {forgotPasswordState === ForgotPasswordState.NOT_SEND ?
                        'No worries, we’ll send you reset instructions.'
                        : `We sent a password reset link to ${getResetPasswordFormValues('email')}.`}
                    </p>
                  </div>
                </div>
                <div>
                  {forgotPasswordState === ForgotPasswordState.NOT_SEND ? (
                    <form
                      className="flex flex-col gap-5"
                      onSubmit={handleSubmitResetPasswordForm(onResetPasswordSubmit)}
                    >
                      <div className="flex flex-col gap-2">
                        <Controller
                          control={controlResetPasswordForm}
                          name="email"
                          render={({ field, formState: { errors } }) => (
                            <Input
                              label="Email"
                              placeholder="Enter your email"
                              errorText={errors.email?.message as string}
                              {...field}
                            />
                          )}
                        />
                        {!!resetPasswordFormErrors.root && !Object.keys(resetPasswordFormErrors).filter(key => key !== 'root').length && (
                          <p
                            className={`text-small text-error-600`}>{resetPasswordFormErrors.root.message}</p>
                        )}
                      </div>
                      <Button
                        variant="primaryBrand"
                        size="large"
                        type="submit"
                      >
                        Reset password
                      </Button>
                    </form>
                  ) : (
                    <div className="flex flex-col gap-6 items-center">
                      {/*<Button variant="primaryBrand" size="large" className="w-full">*/}
                      {/*  Open email app*/}
                      {/*</Button>*/}
                      <div className="flex gap-1 items-center sm:flex-col">Didn’t receive the email? <Button
                        variant="nakedBrandLink" className="p-0"
                        onClick={handleSubmitResetPasswordForm(onResetPasswordSubmit)}>Click to resend</Button></div>
                    </div>
                  )}
                </div>
                <Button
                  type="button"
                  variant="nakedGrayLink"
                  className="p-0 self-center"
                  onClick={() => {
                    setForgotPasswordState(() => undefined);
                    setTab(() => ModalTabs.LOGIN);
                  }}
                >
                  <ArrowLeftIcon height={20} width={20} />
                  Back to sign in
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </LandingContext.Provider>
  );
};

export default LandingContext;
