import { IconProps } from 'components/icons/types';

export const SlackIcon = (props: IconProps) => {
  const { width = 145, height = 145, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox='0 0 145 145' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_7145_83032)'>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M35.3615 21.8388C39.4382 11.6568 50.9483 6.7283 61.1303 10.805L122.468 35.3632C132.65 39.4398 137.579 50.9499 133.502 61.132L108.944 122.47C104.867 132.652 93.3571 137.58 83.175 133.504L21.8372 108.945C11.6551 104.869 6.72666 93.3587 10.8033 83.1767L35.3615 21.8388Z'
              fill='#3F0F3F' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M51.1333 74.8795C52.7191 70.9188 57.2784 69.0448 61.3561 70.6775C65.4339 72.3101 67.4401 76.8128 65.8544 80.7735L58.8087 98.3711C57.223 102.332 52.6636 104.206 48.5859 102.573C44.5081 100.94 42.502 96.4375 44.0876 92.4771L51.1333 74.8795Z'
              fill='#E01E5A' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M74.8787 93.1732C70.9181 91.5875 69.0442 87.0281 70.6768 82.9504C72.3094 78.8726 76.8121 76.8664 80.7727 78.4521L98.3705 85.4979C102.331 87.0836 104.205 91.6429 102.572 95.7207C100.94 99.7984 96.4371 101.805 92.4765 100.219L74.8787 93.1732Z'
              fill='#ECB22D' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M85.4966 45.9341C87.0824 41.9735 91.6417 40.0995 95.7194 41.7321C99.7972 43.3648 101.803 47.8675 100.218 51.828L93.1719 69.4259C91.5862 73.3865 87.0269 75.2604 82.9492 73.6278C78.8714 71.9952 76.8652 67.4925 78.4509 63.5318L85.4966 45.9341Z'
              fill='#2FB67C' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M45.9346 58.8099C41.9741 57.2242 40.1001 52.6648 41.7327 48.5871C43.3653 44.5094 47.868 42.5031 51.8286 44.0888L69.4264 51.1346C73.387 52.7203 75.261 57.2796 73.6284 61.3574C71.9957 65.4351 67.493 67.4414 63.5323 65.8556L45.9346 58.8099Z'
              fill='#36C5F1' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M63.8003 100.122C62.1677 104.2 64.1361 108.797 68.2139 110.43C72.2916 112.062 76.8888 110.094 78.5214 106.016C80.154 101.938 78.1856 97.3413 74.1079 95.7087L66.7473 92.7617L63.8003 100.122Z'
              fill='#ECB22D' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M100.124 80.504L92.763 77.557L95.71 70.1965C97.3426 66.1187 101.94 64.1503 106.018 65.7829C110.095 67.4156 112.064 72.0127 110.431 76.0904C108.798 80.1682 104.201 82.1366 100.124 80.504Z'
              fill='#2FB67C' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.1816 63.7985L51.5421 66.7455L48.5951 74.1061C46.9625 78.1838 42.3653 80.1523 38.2876 78.5196C34.2099 76.887 32.2414 72.2898 33.8741 68.2121C35.5067 64.1344 40.1038 62.1659 44.1816 63.7985Z'
              fill='#E01E5A' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M80.5048 44.1803L77.5578 51.5408L70.1973 48.5938C66.1195 46.9612 64.1511 42.3641 65.7837 38.2863C67.4164 34.2086 72.0135 32.2401 76.0913 33.8728C80.169 35.5054 82.1374 40.1026 80.5048 44.1803Z'
              fill='#36C5F1' />
      </g>
      <defs>
        <clipPath id='clip0_7145_83032'>
          <rect width='111' height='111' fill='white' transform='translate(41.2578) rotate(21.82)' />
        </clipPath>
      </defs>
    </svg>

  );
};
