export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
};

export type AcceptApplicationMutation = {
  __typename?: 'AcceptApplicationMutation';
  autoAddToGroupError?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ActivationPeriodNode = {
  __typename?: 'ActivationPeriodNode';
  periodAll: Scalars['Float'];
  periodLast: Scalars['Float'];
};

export type ActivityResult = ConnectNode | HelpNode | HelpReactionNode | JoinedMemberNode | WorkedWithRequestNode;

export type AnalyticRecap = {
  __typename?: 'AnalyticRecap';
  leftMembers: Scalars['Int'];
  newMembers: Scalars['Int'];
  topMessages: Array<TopMessageNode>;
  topReactions: Array<TopReactionNode>;
  totalMessages: Scalars['Int'];
  totalReactions: Scalars['Int'];
};

/** An enumeration. */
export enum AppType {
  Discord = 'DISCORD',
  Slack = 'SLACK',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP'
}

export type ApplicationFieldInput = {
  delete?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  orderId: Scalars['Int'];
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  type: LandingApplicationFieldTypeChoices;
};

export type ApplicationFieldMutation = {
  __typename?: 'ApplicationFieldMutation';
  field?: Maybe<ApplicationFieldNode>;
};

export type ApplicationFieldNode = {
  __typename?: 'ApplicationFieldNode';
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  orderId: Scalars['Int'];
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  type: LandingApplicationFieldTypeChoices;
};

export type ApplicationsNode = {
  __typename?: 'ApplicationsNode';
  accepted: Scalars['Int'];
  new: Scalars['Int'];
  rejected: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type ApplicationsResponse = {
  __typename?: 'ApplicationsResponse';
  applications: Array<CommunityApplicationNode>;
  count?: Maybe<Scalars['Int']>;
};

export type BlogNode = {
  __typename?: 'BlogNode';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fullUrl: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CanHelpMutation = {
  __typename?: 'CanHelpMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CityNode = {
  __typename?: 'CityNode';
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** An enumeration. */
export enum CommunityAppEnum {
  /** Discord */
  Discord = 'DISCORD',
  /** Slack */
  Slack = 'SLACK',
  /** Telegram */
  Telegram = 'TELEGRAM',
  /** WhatsApp */
  Whatsapp = 'WHATSAPP'
}

export type CommunityApplicationAnswer = {
  __typename?: 'CommunityApplicationAnswer';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommunityApplicationNode = {
  __typename?: 'CommunityApplicationNode';
  answers: Array<CommunityApplicationAnswer>;
  countMessages?: Maybe<Scalars['Int']>;
  countReactions?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inChat?: Maybe<Scalars['Boolean']>;
  member?: Maybe<CommunityMemberNode>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: CommunityCommunityApplicationStatusChoices;
};

/** An enumeration. */
export enum CommunityCommunityAppChoices {
  /** Discord */
  Discord = 'DISCORD',
  /** Slack */
  Slack = 'SLACK',
  /** Telegram */
  Telegram = 'TELEGRAM',
  /** WhatsApp */
  Whatsapp = 'WHATSAPP'
}

/** An enumeration. */
export enum CommunityCommunityApplicationStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** New */
  New = 'NEW',
  /** Rejected */
  Rejected = 'REJECTED'
}

/** An enumeration. */
export enum CommunityCommunityJoinPolicyChoices {
  /** After Apply */
  AfterApply = 'AFTER_APPLY',
  /** After Approve */
  AfterApprove = 'AFTER_APPROVE'
}

/** An enumeration. */
export enum CommunityCommunitySubscriptionTypeChoices {
  /** Free */
  Free = 'FREE',
  /** Paid */
  Paid = 'PAID'
}

/** An enumeration. */
export enum CommunityLandingWebflowAppTypeChoices {
  /** Discord */
  Discord = 'DISCORD',
  /** Slack */
  Slack = 'SLACK',
  /** Telegram */
  Telegram = 'TELEGRAM',
  /** WhatsApp */
  Whatsapp = 'WHATSAPP'
}

/** An enumeration. */
export enum CommunityLandingWebflowQuestionnaireTypeChoices {
  /** ACC */
  Acc = 'ACC',
  /** MZP */
  Mzp = 'MZP',
  /** Reasons */
  Reasons = 'REASONS',
  /** Simple */
  Simple = 'SIMPLE'
}

export type CommunityLogoMutation = {
  __typename?: 'CommunityLogoMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CommunityMediumFeedConnectMutation = {
  __typename?: 'CommunityMediumFeedConnectMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CommunityMemberNode = {
  __typename?: 'CommunityMemberNode';
  community?: Maybe<CommunityNode>;
  role: RoleEnum;
  subscription: MemberSubscriptionNode;
};

export type CommunityNode = {
  __typename?: 'CommunityNode';
  /** The app that community uses to connect with users. Empty means KYG without chat is used */
  app?: Maybe<CommunityCommunityAppChoices>;
  description?: Maybe<Scalars['String']>;
  /** If true, landing page will have apply button */
  displayLandingApplication: Scalars['Boolean'];
  /** Custom domain for community. Has priority over subdomain */
  domain?: Maybe<Scalars['String']>;
  historyDownloadError?: Maybe<Scalars['String']>;
  historyDownloadFinished?: Maybe<Scalars['DateTime']>;
  historyDownloadStarted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isTelegramConnected?: Maybe<Scalars['Boolean']>;
  isWhatsappConnected?: Maybe<Scalars['Boolean']>;
  isWhatsappGroupJoined?: Maybe<Scalars['Boolean']>;
  isWhatsappGroupSet?: Maybe<Scalars['Boolean']>;
  joinPolicy: CommunityCommunityJoinPolicyChoices;
  logo?: Maybe<Scalars['String']>;
  mediumJoinLink?: Maybe<Scalars['String']>;
  /** If true, community members have to confirm their chat membership on /members */
  membersConfirmation: Scalars['Boolean'];
  membersCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pricing?: Maybe<PricingNode>;
  stripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  stripeDetailsSubmitted?: Maybe<Scalars['Boolean']>;
  stripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  /** Subdomain for community. Will be lower cased. */
  subdomain?: Maybe<Scalars['String']>;
  subscriptionConnectionLink?: Maybe<Scalars['String']>;
  subscriptionType: CommunityCommunitySubscriptionTypeChoices;
  telegramConnectionError?: Maybe<Scalars['String']>;
  telegramJoinChatLink?: Maybe<Scalars['String']>;
  videoEmbeddedLink?: Maybe<Scalars['String']>;
  whatsappJoinChatLink?: Maybe<Scalars['String']>;
};

export type CommunitySettingsMutation = {
  __typename?: 'CommunitySettingsMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CompaniesCompanySizeChoices {
  /** 1-15 */
  A_1 = 'A_1',
  /** 15-75 */
  A_2 = 'A_2',
  /** 75-250 */
  A_3 = 'A_3',
  /** 250-1k */
  A_4 = 'A_4',
  /** 1k-10k */
  A_5 = 'A_5',
  /** 1k-10k */
  A_5_5 = 'A_5_5'
}

export type CompanyExperienceInput = {
  company?: InputMaybe<CompanyInput>;
  description?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  years?: InputMaybe<Scalars['String']>;
};

export type CompanyExperienceNode = {
  __typename?: 'CompanyExperienceNode';
  company?: Maybe<CompanyNode>;
  description?: Maybe<Scalars['String']>;
  fromMonth?: Maybe<Scalars['Int']>;
  fromYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  position?: Maybe<JobTitleNode>;
  positionRaw?: Maybe<Scalars['String']>;
  toMonth?: Maybe<Scalars['Int']>;
  toYear?: Maybe<Scalars['Int']>;
};

export type CompanyInput = {
  description?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CompanyNode = {
  __typename?: 'CompanyNode';
  description?: Maybe<Scalars['String']>;
  direction?: Maybe<DirectionNode>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ConfirmPhoneNumberMutation = {
  __typename?: 'ConfirmPhoneNumberMutation';
  communitiesFound?: Maybe<Scalars['Int']>;
};

export enum ConfirmedEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type ConnectConfirmMutation = {
  __typename?: 'ConnectConfirmMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConnectMutation = {
  __typename?: 'ConnectMutation';
  connectLink?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConnectNode = {
  __typename?: 'ConnectNode';
  confirmed: ConfirmedEnum;
  createdAt: Scalars['DateTime'];
  fromUser?: Maybe<UserNode>;
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type Connection = {
  __typename?: 'Connection';
  connect?: Maybe<ConnectionConnectNode>;
  connected: Scalars['Boolean'];
  helpReaction?: Maybe<ConnectionHelpReactionNode>;
  match?: Maybe<Array<Scalars['String']>>;
  workedWith?: Maybe<ConnectionWorkedWithNode>;
};

export type ConnectionConnectNode = {
  __typename?: 'ConnectionConnectNode';
  confirmed: ConfirmedEnum;
  connectId: Scalars['ID'];
  fromThey: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type ConnectionHelpReactionNode = {
  __typename?: 'ConnectionHelpReactionNode';
  help: Scalars['String'];
  helpId: Scalars['ID'];
};

export type ConnectionWorkedWithNode = {
  __typename?: 'ConnectionWorkedWithNode';
  confirmed: ConfirmedEnum;
  fromThey: Scalars['Boolean'];
  workedWithId: Scalars['ID'];
};

export type CreateCommunityMutation = {
  __typename?: 'CreateCommunityMutation';
  community?: Maybe<CommunityNode>;
};

export type CreateCompanyMutation = {
  __typename?: 'CreateCompanyMutation';
  company?: Maybe<CompanyNode>;
};

export type CreateFeaturedMutation = {
  __typename?: 'CreateFeaturedMutation';
  featured?: Maybe<FeaturedNode>;
};

export type CreateHobbyMutation = {
  __typename?: 'CreateHobbyMutation';
  hobby?: Maybe<TagNode>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateJobTitleMutation = {
  __typename?: 'CreateJobTitleMutation';
  jobTitle?: Maybe<JobTitleNode>;
};

export type CreateQualificationMutation = {
  __typename?: 'CreateQualificationMutation';
  qualification?: Maybe<QualificationNode>;
};

export type CreateSkillMutation = {
  __typename?: 'CreateSkillMutation';
  ok?: Maybe<Scalars['Boolean']>;
  skill?: Maybe<TagNode>;
};

export type CreateUniversityMutation = {
  __typename?: 'CreateUniversityMutation';
  university?: Maybe<UniversityNode>;
};

export type CtaFieldsInput = {
  buttonText: Scalars['String'];
  title: Scalars['String'];
};

export type CurrentHelpNode = {
  __typename?: 'CurrentHelpNode';
  createdAt: Scalars['DateTime'];
  currentUserReacted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  reactedBy: Array<Scalars['String']>;
  reactedByV2: Array<ReactedByNode>;
};

export type DeleteEducationMutation = {
  __typename?: 'DeleteEducationMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteExperienceMutation = {
  __typename?: 'DeleteExperienceMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteFeaturedMutation = {
  __typename?: 'DeleteFeaturedMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteProfileMutation = {
  __typename?: 'DeleteProfileMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DirectionGroupNode = {
  __typename?: 'DirectionGroupNode';
  companiesCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  usersCount?: Maybe<Scalars['Int']>;
};

export type DirectionNode = {
  __typename?: 'DirectionNode';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditCompanyMutation = {
  __typename?: 'EditCompanyMutation';
  company?: Maybe<CompanyNode>;
};

export type EditFeaturedMutation = {
  __typename?: 'EditFeaturedMutation';
  featured?: Maybe<FeaturedNode>;
};

export type EditJobTitleMutation = {
  __typename?: 'EditJobTitleMutation';
  jobTitle?: Maybe<JobTitleNode>;
};

export type EducationInput = {
  speciality?: InputMaybe<Scalars['String']>;
  university?: InputMaybe<UniversityInput>;
  years?: InputMaybe<Scalars['String']>;
};

export type EducationNode = {
  __typename?: 'EducationNode';
  description?: Maybe<Scalars['String']>;
  fromMonth?: Maybe<Scalars['Int']>;
  fromYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  qualification?: Maybe<QualificationNode>;
  speciality?: Maybe<Scalars['String']>;
  toMonth?: Maybe<Scalars['Int']>;
  toYear?: Maybe<Scalars['Int']>;
  university?: Maybe<UniversityNode>;
  years?: Maybe<Scalars['String']>;
};

export type FeaturedInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type FeaturedNode = {
  __typename?: 'FeaturedNode';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FeedMessageNode = {
  __typename?: 'FeedMessageNode';
  rawMessage?: Maybe<Scalars['String']>;
  reactions?: Maybe<Array<FeedReactionsNode>>;
  reactionsCount?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
};

export type FeedReactionsNode = {
  __typename?: 'FeedReactionsNode';
  count?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['String']>;
};

export type FiltersV2 = {
  timeframe: TimeframeEnum;
};

export type HeatmapNode = {
  __typename?: 'HeatmapNode';
  messages: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type HelpNode = {
  __typename?: 'HelpNode';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  user: UserNode;
};

export type HelpReactMutation = {
  __typename?: 'HelpReactMutation';
  id?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type HelpReactionNode = {
  __typename?: 'HelpReactionNode';
  createdAt: Scalars['DateTime'];
  helpMsg?: Maybe<HelpNode>;
  message?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type IncidentNowMutation = {
  __typename?: 'IncidentNowMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type JobTitleGroupNode = {
  __typename?: 'JobTitleGroupNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  usersCount?: Maybe<Scalars['Int']>;
};

export type JobTitleNode = {
  __typename?: 'JobTitleNode';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type JobTypeChoiceType = {
  __typename?: 'JobTypeChoiceType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum JoinPolicy {
  AfterApply = 'AFTER_APPLY',
  AfterApprove = 'AFTER_APPROVE'
}

export type JoinedMemberNode = {
  __typename?: 'JoinedMemberNode';
  joinedAt: Scalars['DateTime'];
  user: UserNode;
};

export type LandingAiMutation = {
  __typename?: 'LandingAIMutation';
  landing: LandingWebflowNode;
};

export type LandingAiMembersCancelMutation = {
  __typename?: 'LandingAiMembersCancelMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type LandingAiMembersRegenerateMutation = {
  __typename?: 'LandingAiMembersRegenerateMutation';
  members: Array<LandingMemberNode>;
};

/** An enumeration. */
export enum LandingApplicationFieldTypeChoices {
  /** Email */
  Email = 'EMAIL',
  /** Phone */
  Phone = 'PHONE',
  /** Text */
  Text = 'TEXT',
  /** Long Text */
  Textarea = 'TEXTAREA',
  /** URL */
  Url = 'URL'
}

export type LandingApplyInput = {
  fieldId: Scalars['String'];
  fieldLabel: Scalars['String'];
  value: Scalars['String'];
};

export type LandingApplyMutation = {
  __typename?: 'LandingApplyMutation';
  inviteLink?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type LandingBenefitsFields = {
  __typename?: 'LandingBenefitsFields';
  description?: Maybe<Scalars['String']>;
  item1?: Maybe<Scalars['String']>;
  item2?: Maybe<Scalars['String']>;
  item3?: Maybe<Scalars['String']>;
  item4?: Maybe<Scalars['String']>;
  item5?: Maybe<Scalars['String']>;
  item6?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LandingBenefitsInput = {
  description: Scalars['String'];
  item1?: InputMaybe<Scalars['String']>;
  item2?: InputMaybe<Scalars['String']>;
  item3?: InputMaybe<Scalars['String']>;
  item4?: InputMaybe<Scalars['String']>;
  item5?: InputMaybe<Scalars['String']>;
  item6?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LandingBuilderVideoSeenMutation = {
  __typename?: 'LandingBuilderVideoSeenMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type LandingCtaFields = {
  __typename?: 'LandingCtaFields';
  buttonText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LandingFieldsMutation = {
  __typename?: 'LandingFieldsMutation';
  landing?: Maybe<LandingWebflowNode>;
};

export type LandingHeaderImageMutation = {
  __typename?: 'LandingHeaderImageMutation';
  landing?: Maybe<LandingWebflowNode>;
};

export type LandingHeaderInput = {
  headerSubtitle?: InputMaybe<Scalars['String']>;
  headerTags?: InputMaybe<Scalars['String']>;
  headerTitle: Scalars['String'];
  membersNumber?: InputMaybe<Scalars['String']>;
  youtubeVideoUrl?: InputMaybe<Scalars['String']>;
};

export type LandingJoinSettingsMutation = {
  __typename?: 'LandingJoinSettingsMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type LandingMemberImageMutation = {
  __typename?: 'LandingMemberImageMutation';
  member?: Maybe<LandingMemberNode>;
};

export type LandingMemberInput = {
  about?: InputMaybe<Scalars['String']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['String']>;
};

export type LandingMemberMutation = {
  __typename?: 'LandingMemberMutation';
  member?: Maybe<LandingMemberNode>;
};

export type LandingMemberNode = {
  __typename?: 'LandingMemberNode';
  about: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
};

export type LandingPublishMutation = {
  __typename?: 'LandingPublishMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type LandingRequirementsFields = {
  __typename?: 'LandingRequirementsFields';
  content?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LandingSecondaryButtonMutation = {
  __typename?: 'LandingSecondaryButtonMutation';
  landing?: Maybe<LandingWebflowNode>;
};

export enum LandingSectionEnum {
  Benefits = 'BENEFITS',
  Cta = 'CTA',
  Header = 'HEADER',
  Requirements = 'REQUIREMENTS'
}

export enum LandingSectionFieldEnum {
  Benefits = 'BENEFITS',
  ButtonText = 'BUTTON_TEXT',
  Description = 'DESCRIPTION',
  Tags = 'TAGS',
  Title = 'TITLE'
}

export type LandingWebflowNode = {
  __typename?: 'LandingWebflowNode';
  appType: CommunityLandingWebflowAppTypeChoices;
  applicationFields: Array<ApplicationFieldNode>;
  benefits: LandingBenefitsFields;
  builderVideoSeen: Scalars['Boolean'];
  community: CommunityNode;
  cta: LandingCtaFields;
  headerButtonCustom?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerSecondaryUrl?: Maybe<Scalars['String']>;
  headerSecondaryUrlTitle?: Maybe<Scalars['String']>;
  headerSubtitle: Scalars['String'];
  headerTags?: Maybe<Scalars['String']>;
  headerTitle: Scalars['String'];
  icons?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  members: Array<LandingMemberNode>;
  /** If empty, total number of members of the app will be used. */
  membersNumber?: Maybe<Scalars['String']>;
  membersStatus?: Maybe<MembersStatusEnum>;
  questionnaireType: CommunityLandingWebflowQuestionnaireTypeChoices;
  requirements: LandingRequirementsFields;
  youtubeVideoUrl?: Maybe<Scalars['String']>;
};

export type LeftMemberConnection = {
  __typename?: 'LeftMemberConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeftMemberEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LeftMember` and its cursor. */
export type LeftMemberEdge = {
  __typename?: 'LeftMemberEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LeftMemberNode>;
};

export type LeftMemberNode = {
  __typename?: 'LeftMemberNode';
  image?: Maybe<Scalars['String']>;
  leftAt: Scalars['Date'];
  link?: Maybe<MemberLinkNode>;
  name?: Maybe<Scalars['String']>;
  rawId: Scalars['String'];
  user?: Maybe<UserNode>;
};

export enum LifetimeEnum {
  Dedicated = 'dedicated',
  EarlyBirds = 'early_birds',
  Engaged = 'engaged',
  Newcomers = 'newcomers',
  Unknown = 'unknown',
  Veterans = 'veterans'
}

export type LoadCitiesMutation = {
  __typename?: 'LoadCitiesMutation';
  cities: Array<CityNode>;
};

export type LoadCompaniesMutation = {
  __typename?: 'LoadCompaniesMutation';
  companies?: Maybe<Array<CompanyNode>>;
};

export type LoadHobbiesMutation = {
  __typename?: 'LoadHobbiesMutation';
  hobbies: Array<TagNode>;
};

export type LoadQualificationsMutation = {
  __typename?: 'LoadQualificationsMutation';
  qualifications?: Maybe<Array<QualificationNode>>;
};

export type LoadSkillsMutation = {
  __typename?: 'LoadSkillsMutation';
  skills: Array<TagNode>;
};

export type LoadUniversitiesMutation = {
  __typename?: 'LoadUniversitiesMutation';
  universities?: Maybe<Array<UniversityNode>>;
};

export type LoginMutation = {
  __typename?: 'LoginMutation';
  community?: Maybe<CommunityNode>;
  user?: Maybe<UserNode>;
};

export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MemberConnectTelegramMutation = {
  __typename?: 'MemberConnectTelegramMutation';
  communitiesFound?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type MemberConnectWhatsappConfirmMutation = {
  __typename?: 'MemberConnectWhatsappConfirmMutation';
  community?: Maybe<CommunityNode>;
  user?: Maybe<UserNode>;
};

export type MemberConnectWhatsappLinkMutation = {
  __typename?: 'MemberConnectWhatsappLinkMutation';
  link?: Maybe<Scalars['String']>;
};

export type MemberCountryNode = {
  __typename?: 'MemberCountryNode';
  countryCode: Scalars['String'];
  members: Scalars['Int'];
};

export type MemberLanguageNode = {
  __typename?: 'MemberLanguageNode';
  languageCode: Scalars['String'];
  members: Scalars['Int'];
};

export type MemberLifetimeNode = {
  __typename?: 'MemberLifetimeNode';
  group: LifetimeEnum;
  members: Scalars['Int'];
};

export type MemberLinkNode = {
  __typename?: 'MemberLinkNode';
  service: AppType;
  url: Scalars['String'];
};

export type MemberSubscriptionNode = {
  __typename?: 'MemberSubscriptionNode';
  status: MemberSubscriptionStatusEnum;
  stripeSubscription?: Maybe<StripeSubscription>;
};

/** An enumeration. */
export enum MemberSubscriptionStatusEnum {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE'
}

export enum MembersStatusEnum {
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type MembersV2Node = {
  __typename?: 'MembersV2Node';
  /** Members joined */
  joined: Scalars['Int'];
  /** Members left */
  left: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type MessageMutation = {
  __typename?: 'MessageMutation';
  connectLink?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type MessageTypeNode = {
  __typename?: 'MessageTypeNode';
  messageType: Scalars['String'];
  messages: Scalars['Int'];
};

export type MessagesNode = {
  __typename?: 'MessagesNode';
  /** Avg messages per user */
  avgMessages: Scalars['Float'];
  /** Messages sent */
  messages: Scalars['Int'];
  timestamp: Scalars['DateTime'];
  /** Users who sent messages */
  users: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptApplication?: Maybe<AcceptApplicationMutation>;
  blogConnectMedium?: Maybe<CommunityMediumFeedConnectMutation>;
  communitySettings?: Maybe<CommunitySettingsMutation>;
  communitySettingsLogo?: Maybe<CommunityLogoMutation>;
  confirmPhoneNumber?: Maybe<ConfirmPhoneNumberMutation>;
  connect?: Maybe<ConnectMutation>;
  connectConfirm?: Maybe<ConnectConfirmMutation>;
  connectTelegram?: Maybe<SetCommunityTelegramChatId>;
  connectWhatsapp?: Maybe<WhatsappConnectMutation>;
  connectWhatsappConfirm?: Maybe<WhatsappConnectConfirmMutation>;
  createCommunity?: Maybe<CreateCommunityMutation>;
  createCompany?: Maybe<CreateCompanyMutation>;
  createFeatured?: Maybe<CreateFeaturedMutation>;
  createHobby?: Maybe<CreateHobbyMutation>;
  createJobTitle?: Maybe<CreateJobTitleMutation>;
  createQualification?: Maybe<CreateQualificationMutation>;
  createSkill?: Maybe<CreateSkillMutation>;
  createUniversity?: Maybe<CreateUniversityMutation>;
  deleteEducation?: Maybe<DeleteEducationMutation>;
  deleteExperience?: Maybe<DeleteExperienceMutation>;
  deleteFeatured?: Maybe<DeleteFeaturedMutation>;
  deleteProfile?: Maybe<DeleteProfileMutation>;
  editCompany?: Maybe<EditCompanyMutation>;
  editFeatured?: Maybe<EditFeaturedMutation>;
  editJobTitle?: Maybe<EditJobTitleMutation>;
  harvestUser?: Maybe<UsersHarvMutation>;
  helpCan?: Maybe<CanHelpMutation>;
  helpNeed?: Maybe<NeedHelpMutation>;
  helpReact?: Maybe<HelpReactMutation>;
  incidentNow?: Maybe<IncidentNowMutation>;
  landingAi?: Maybe<LandingAiMutation>;
  landingAiMembersCancel?: Maybe<LandingAiMembersCancelMutation>;
  landingAiMembersRegenerate?: Maybe<LandingAiMembersRegenerateMutation>;
  landingApplicationField?: Maybe<ApplicationFieldMutation>;
  landingApply?: Maybe<LandingApplyMutation>;
  landingBuilderVideoSeen?: Maybe<LandingBuilderVideoSeenMutation>;
  landingJoinSettings?: Maybe<LandingJoinSettingsMutation>;
  landingMember?: Maybe<LandingMemberMutation>;
  landingMemberImage?: Maybe<LandingMemberImageMutation>;
  landingPublish?: Maybe<LandingPublishMutation>;
  landingSecondaryButton?: Maybe<LandingSecondaryButtonMutation>;
  landingSetFields?: Maybe<LandingFieldsMutation>;
  landingSetHeaderImage?: Maybe<LandingHeaderImageMutation>;
  loadCities?: Maybe<LoadCitiesMutation>;
  loadCompanies?: Maybe<LoadCompaniesMutation>;
  loadHobbies?: Maybe<LoadHobbiesMutation>;
  loadQualifications?: Maybe<LoadQualificationsMutation>;
  loadSkills?: Maybe<LoadSkillsMutation>;
  loadUniversities?: Maybe<LoadUniversitiesMutation>;
  login?: Maybe<LoginMutation>;
  logout?: Maybe<LogoutMutation>;
  memberConnectTelegram?: Maybe<MemberConnectTelegramMutation>;
  memberConnectWhatsappConfirm?: Maybe<MemberConnectWhatsappConfirmMutation>;
  memberConnectWhatsappLink?: Maybe<MemberConnectWhatsappLinkMutation>;
  message?: Maybe<MessageMutation>;
  oneClickLogin?: Maybe<OneClickLoginMutation>;
  register?: Maybe<RegisterMutation>;
  rejectApplication?: Maybe<RejectApplicationMutation>;
  removeImageProfile?: Maybe<RemoveImageUserMutation>;
  resetPassword?: Maybe<ResetPasswordMutation>;
  resetPasswordConfirm?: Maybe<ResetPasswordConfirmMutation>;
  saveHobbies?: Maybe<SetHobbiesMutation>;
  saveImageProfile?: Maybe<SaveImageUserMutation>;
  saveLinkedin?: Maybe<SaveLinkedinMutation>;
  saveLocation?: Maybe<UserLocationMutation>;
  saveOnboardingFinished?: Maybe<SaveOnboardingFinishedMutation>;
  saveSkills?: Maybe<SetSkillsMutation>;
  saveSocial?: Maybe<UserSocialMutation>;
  setCommunityDescription?: Maybe<SetCommunityDescriptionMutation>;
  setCommunityGoals?: Maybe<SetCommunityGoalsMutation>;
  setPricing?: Maybe<SetPricing>;
  setSubscriptionType?: Maybe<SetSubscriptionType>;
  signup?: Maybe<SignUpMutation>;
  signupConfirm?: Maybe<SignUpConfirmMutation>;
  start?: Maybe<StartMutation>;
  telegramAuth?: Maybe<TelegramAuthMutation>;
  updateBaseProfile?: Maybe<UpdateBaseUserMutation>;
  updateEducation?: Maybe<UpdateEducationMutation>;
  updateExperience?: Maybe<UpdateExperienceMutation>;
  updatePhoneNumber?: Maybe<UpdatePhoneNumberMutation>;
  workedWith?: Maybe<WorkedWithMutation>;
  workedWithConfirm?: Maybe<WorkedWithConfirmMutation>;
};


export type MutationAcceptApplicationArgs = {
  applicationId: Scalars['Int'];
};


export type MutationBlogConnectMediumArgs = {
  mediumJoinLink: Scalars['String'];
};


export type MutationCommunitySettingsArgs = {
  joinChatLink?: InputMaybe<Scalars['String']>;
  joinPolicy?: InputMaybe<JoinPolicy>;
  name: Scalars['String'];
  subdomain: Scalars['String'];
};


export type MutationCommunitySettingsLogoArgs = {
  logo: Scalars['String'];
};


export type MutationConfirmPhoneNumberArgs = {
  code: Scalars['String'];
};


export type MutationConnectArgs = {
  message: Scalars['String'];
  toUserId: Scalars['ID'];
};


export type MutationConnectConfirmArgs = {
  confirm: Scalars['Boolean'];
  connectId: Scalars['ID'];
};


export type MutationConnectTelegramArgs = {
  inviteLink: Scalars['String'];
};


export type MutationConnectWhatsappArgs = {
  inviteLink: Scalars['String'];
};


export type MutationCreateCommunityArgs = {
  app: CommunityAppEnum;
  communityName: Scalars['String'];
  communitySubdomain?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCompanyArgs = {
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateFeaturedArgs = {
  description: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};


export type MutationCreateHobbyArgs = {
  hobbyInput?: InputMaybe<TagInput>;
};


export type MutationCreateJobTitleArgs = {
  name: Scalars['String'];
};


export type MutationCreateQualificationArgs = {
  name: Scalars['String'];
};


export type MutationCreateSkillArgs = {
  skillInput?: InputMaybe<TagInput>;
};


export type MutationCreateUniversityArgs = {
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationDeleteEducationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteExperienceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeaturedArgs = {
  id: Scalars['Int'];
};


export type MutationEditCompanyArgs = {
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationEditFeaturedArgs = {
  description: Scalars['String'];
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};


export type MutationEditJobTitleArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationHarvestUserArgs = {
  user?: InputMaybe<UserHarvInput>;
};


export type MutationHelpCanArgs = {
  message: Scalars['String'];
};


export type MutationHelpNeedArgs = {
  message: Scalars['String'];
};


export type MutationHelpReactArgs = {
  helpMessageId: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
};


export type MutationLandingAiArgs = {
  field?: InputMaybe<LandingSectionFieldEnum>;
  message?: InputMaybe<Scalars['String']>;
  section?: InputMaybe<LandingSectionEnum>;
};


export type MutationLandingApplicationFieldArgs = {
  field: ApplicationFieldInput;
};


export type MutationLandingApplyArgs = {
  values: Array<LandingApplyInput>;
};


export type MutationLandingJoinSettingsArgs = {
  joinChatLink?: InputMaybe<Scalars['String']>;
  joinPolicy: JoinPolicy;
};


export type MutationLandingMemberArgs = {
  member: LandingMemberInput;
};


export type MutationLandingMemberImageArgs = {
  image: Scalars['String'];
  memberId: Scalars['ID'];
};


export type MutationLandingSecondaryButtonArgs = {
  title: Scalars['String'];
  url: Scalars['String'];
};


export type MutationLandingSetFieldsArgs = {
  benefits?: InputMaybe<LandingBenefitsInput>;
  cta?: InputMaybe<CtaFieldsInput>;
  header?: InputMaybe<LandingHeaderInput>;
  requirements?: InputMaybe<RequirementsFieldsInput>;
};


export type MutationLandingSetHeaderImageArgs = {
  image: Scalars['String'];
};


export type MutationLoadCitiesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoadCompaniesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoadHobbiesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoadQualificationsArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoadSkillsArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoadUniversitiesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMemberConnectTelegramArgs = {
  authDate: Scalars['String'];
  firstName: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationMemberConnectWhatsappConfirmArgs = {
  codeId: Scalars['String'];
};


export type MutationMessageArgs = {
  toUserId: Scalars['ID'];
};


export type MutationOneClickLoginArgs = {
  inviteHash: Scalars['String'];
};


export type MutationRegisterArgs = {
  app: CommunityAppEnum;
  communityName: Scalars['String'];
  communitySubdomain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRejectApplicationArgs = {
  applicationId: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordConfirmArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationSaveHobbiesArgs = {
  hobbies?: InputMaybe<Array<InputMaybe<SetTagInput>>>;
};


export type MutationSaveImageProfileArgs = {
  image: Scalars['String'];
};


export type MutationSaveLinkedinArgs = {
  linkLinkedin: Scalars['String'];
};


export type MutationSaveLocationArgs = {
  cityId: Scalars['ID'];
};


export type MutationSaveSkillsArgs = {
  skills?: InputMaybe<Array<InputMaybe<SetTagInput>>>;
};


export type MutationSaveSocialArgs = {
  linkFacebook?: InputMaybe<Scalars['String']>;
  linkInstagram?: InputMaybe<Scalars['String']>;
  linkLinkedin?: InputMaybe<Scalars['String']>;
  linkTelegram?: InputMaybe<Scalars['String']>;
  linkYoutube?: InputMaybe<Scalars['String']>;
};


export type MutationSetCommunityDescriptionArgs = {
  description: Scalars['String'];
};


export type MutationSetCommunityGoalsArgs = {
  goals: Array<InputMaybe<Scalars['String']>>;
};


export type MutationSetPricingArgs = {
  monthly: Scalars['Decimal'];
};


export type MutationSetSubscriptionTypeArgs = {
  type: SubscriptionTypeEnum;
};


export type MutationSignupArgs = {
  email: Scalars['String'];
  inviteHash?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationSignupConfirmArgs = {
  token: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationStartArgs = {
  inviteLink: Scalars['String'];
};


export type MutationTelegramAuthArgs = {
  initData: Scalars['String'];
};


export type MutationUpdateBaseProfileArgs = {
  about?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkBehance?: InputMaybe<Scalars['String']>;
  linkCalendly?: InputMaybe<Scalars['String']>;
  linkFacebook?: InputMaybe<Scalars['String']>;
  linkGithub?: InputMaybe<Scalars['String']>;
  linkInstagram?: InputMaybe<Scalars['String']>;
  linkLinkedin?: InputMaybe<Scalars['String']>;
  linkTelegram?: InputMaybe<Scalars['String']>;
  linkTiktok?: InputMaybe<Scalars['String']>;
  linkTwitter?: InputMaybe<Scalars['String']>;
  linkYoutube?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEducationArgs = {
  description: Scalars['String'];
  fromMonth?: InputMaybe<Scalars['Int']>;
  fromYear: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  qualificationId: Scalars['ID'];
  toMonth?: InputMaybe<Scalars['Int']>;
  toYear?: InputMaybe<Scalars['Int']>;
  universityId: Scalars['ID'];
};


export type MutationUpdateExperienceArgs = {
  companyId: Scalars['ID'];
  description: Scalars['String'];
  fromMonth?: InputMaybe<Scalars['Int']>;
  fromYear: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  position: Scalars['String'];
  toMonth?: InputMaybe<Scalars['Int']>;
  toYear?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdatePhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationWorkedWithArgs = {
  userId: Scalars['ID'];
};


export type MutationWorkedWithConfirmArgs = {
  confirm: Scalars['Boolean'];
  workedWithId: Scalars['ID'];
};

export type NeedHelpMutation = {
  __typename?: 'NeedHelpMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type OAuthLink = {
  __typename?: 'OAuthLink';
  link: Scalars['String'];
  provider: OAuthProvider;
};

export enum OAuthProvider {
  Google = 'google'
}

export type OneClickLoginMutation = {
  __typename?: 'OneClickLoginMutation';
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PricingNode = {
  __typename?: 'PricingNode';
  monthly: Scalars['Decimal'];
};

export type QualificationNode = {
  __typename?: 'QualificationNode';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activity?: Maybe<Array<ActivityResult>>;
  activityCount: Scalars['Int'];
  allCities: Array<CityNode>;
  analyticsActivationPeriod?: Maybe<ActivationPeriodNode>;
  analyticsApplications?: Maybe<Array<ApplicationsNode>>;
  analyticsHeatmap?: Maybe<Array<HeatmapNode>>;
  analyticsMembers?: Maybe<Array<MembersV2Node>>;
  analyticsMembersCountries?: Maybe<Array<MemberCountryNode>>;
  analyticsMembersLanguages?: Maybe<Array<MemberLanguageNode>>;
  analyticsMembersLeft?: Maybe<Array<LeftMemberNode>>;
  analyticsMembersLeftV2?: Maybe<LeftMemberConnection>;
  analyticsMembersLifetime?: Maybe<Array<MemberLifetimeNode>>;
  analyticsMembersTop?: Maybe<Array<TopMemberNode>>;
  analyticsMembersTopV2?: Maybe<TopMemberConnection>;
  analyticsMessageTypes?: Maybe<Array<MessageTypeNode>>;
  analyticsMessages?: Maybe<Array<MessagesNode>>;
  analyticsReactions?: Maybe<Array<ReactionsNode>>;
  analyticsRecap: AnalyticRecap;
  analyticsStages?: Maybe<Array<StagesNode>>;
  analyticsStats?: Maybe<Array<StatsNode>>;
  analyticsSummaryApplications: SummaryApplicationsNode;
  analyticsSummaryLikes: SummaryLikesNode;
  analyticsSummaryMembers: SummaryMembersNode;
  analyticsSummaryMembersLeft: SummaryMembersLeftNode;
  analyticsSummaryMessages: SummaryMessagesNode;
  application?: Maybe<CommunityApplicationNode>;
  applications?: Maybe<ApplicationsResponse>;
  blogRecent?: Maybe<Array<BlogNode>>;
  blogUrl?: Maybe<Scalars['String']>;
  cities: Array<CityNode>;
  communityInfo?: Maybe<CommunityNode>;
  companies: Array<CompanyNode>;
  currentUser?: Maybe<UserNode>;
  directions?: Maybe<Array<DirectionGroupNode>>;
  education?: Maybe<EducationNode>;
  experience?: Maybe<CompanyExperienceNode>;
  featured?: Maybe<FeaturedNode>;
  hobbies: Array<TagNode>;
  isLoggedIn?: Maybe<Scalars['Boolean']>;
  jobTitles?: Maybe<Array<JobTitleGroupNode>>;
  jobTypeChoices?: Maybe<Array<Maybe<JobTypeChoiceType>>>;
  landing: LandingWebflowNode;
  oauthLinks: Array<OAuthLink>;
  oneClickLoginUser?: Maybe<UserNode>;
  positions: Array<JobTitleNode>;
  qualifications: Array<QualificationNode>;
  requests: Array<RequestNode>;
  skills: Array<TagNode>;
  statMember?: Maybe<StatMemberDumpNode>;
  statMembers?: Maybe<Array<StatMemberNode>>;
  statMessages?: Maybe<Array<FeedMessageNode>>;
  stripeCustomerPortalUrl?: Maybe<Scalars['String']>;
  stripeEmbedded?: Maybe<Scalars['String']>;
  tags: Array<TagNode>;
  telegramConfiguration?: Maybe<TelegramConfiguration>;
  universities: Array<UniversityNode>;
  user?: Maybe<UserNode>;
  users: Array<UserNode>;
};


export type QueryAllCitiesArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAnalyticsApplicationsArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMembersArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMembersLanguagesArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMembersLeftArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMembersLeftV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FiltersV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAnalyticsMembersTopArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMembersTopV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FiltersV2>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAnalyticsMessageTypesArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsMessagesArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsReactionsArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsRecapArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsStatsArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsSummaryApplicationsArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsSummaryLikesArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsSummaryMembersArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsSummaryMembersLeftArgs = {
  filters: FiltersV2;
};


export type QueryAnalyticsSummaryMessagesArgs = {
  filters: FiltersV2;
};


export type QueryApplicationArgs = {
  id: Scalars['Int'];
};


export type QueryApplicationsArgs = {
  status: Array<CommunityCommunityApplicationStatusChoices>;
};


export type QueryCompaniesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryEducationArgs = {
  id: Scalars['Int'];
};


export type QueryExperienceArgs = {
  id: Scalars['ID'];
};


export type QueryFeaturedArgs = {
  id: Scalars['Int'];
};


export type QueryHobbiesArgs = {
  search?: InputMaybe<Scalars['String']>;
  systemOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOauthLinksArgs = {
  inviteHash?: InputMaybe<Scalars['String']>;
  redirect?: InputMaybe<Scalars['String']>;
};


export type QueryOneClickLoginUserArgs = {
  inviteHash?: InputMaybe<Scalars['String']>;
};


export type QueryPositionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryQualificationsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySkillsArgs = {
  search?: InputMaybe<Scalars['String']>;
  systemOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStatMemberArgs = {
  id: Scalars['String'];
  limit: Scalars['Int'];
};


export type QueryStripeCustomerPortalUrlArgs = {
  returnUrl: Scalars['String'];
};


export type QueryUniversitiesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilters>;
};

export type ReactedByNode = {
  __typename?: 'ReactedByNode';
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userId: Scalars['ID'];
};

export type ReactionsNode = {
  __typename?: 'ReactionsNode';
  /** Reactions sent */
  reactions: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type ReferrerNode = {
  __typename?: 'ReferrerNode';
  count: Scalars['Int'];
  referrer: Scalars['String'];
};

export type RegisterMutation = {
  __typename?: 'RegisterMutation';
  community?: Maybe<CommunityNode>;
  user?: Maybe<UserNode>;
};

export type RejectApplicationMutation = {
  __typename?: 'RejectApplicationMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveImageUserMutation = {
  __typename?: 'RemoveImageUserMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestNode = {
  __typename?: 'RequestNode';
  request: Scalars['String'];
};

export type RequirementsFieldsInput = {
  content: Scalars['String'];
  title: Scalars['String'];
};

export type ResetPasswordConfirmMutation = {
  __typename?: 'ResetPasswordConfirmMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordMutation = {
  __typename?: 'ResetPasswordMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type SaveImageUserMutation = {
  __typename?: 'SaveImageUserMutation';
  image: Scalars['String'];
};

export type SaveLinkedinMutation = {
  __typename?: 'SaveLinkedinMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SaveOnboardingFinishedMutation = {
  __typename?: 'SaveOnboardingFinishedMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCommunityDescriptionMutation = {
  __typename?: 'SetCommunityDescriptionMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCommunityGoalsMutation = {
  __typename?: 'SetCommunityGoalsMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetCommunityTelegramChatId = {
  __typename?: 'SetCommunityTelegramChatId';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetHobbiesMutation = {
  __typename?: 'SetHobbiesMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetPricing = {
  __typename?: 'SetPricing';
  pricing?: Maybe<PricingNode>;
};

export type SetSkillsMutation = {
  __typename?: 'SetSkillsMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetSubscriptionType = {
  __typename?: 'SetSubscriptionType';
  next?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetTagInput = {
  tagId: Scalars['Int'];
};

export type SignUpConfirmMutation = {
  __typename?: 'SignUpConfirmMutation';
  community?: Maybe<CommunityNode>;
  user: UserNode;
};

export type SignUpMutation = {
  __typename?: 'SignUpMutation';
  user?: Maybe<UserNode>;
};

export enum StagesEnum {
  Activated = 'activated',
  Engaged = 'engaged',
  NotActivated = 'not_activated',
  Reengaged = 'reengaged',
  Sleeping = 'sleeping'
}

export type StagesNode = {
  __typename?: 'StagesNode';
  members: Scalars['Int'];
  stage: StagesEnum;
};

export type StartMutation = {
  __typename?: 'StartMutation';
  community?: Maybe<CommunityNode>;
};

export type StatMemberDumpNode = {
  __typename?: 'StatMemberDumpNode';
  messages: Scalars['String'];
};

export type StatMemberNode = {
  __typename?: 'StatMemberNode';
  aiAchievements?: Maybe<Scalars['String']>;
  aiCity?: Maybe<Scalars['String']>;
  aiCurrentCompany?: Maybe<Scalars['String']>;
  aiCurrentJob?: Maybe<Scalars['String']>;
  aiLinkedin?: Maybe<Scalars['String']>;
  aiLookingFor?: Maybe<Scalars['String']>;
  aiProjectUrl?: Maybe<Scalars['String']>;
  aiSearchPrompts?: Maybe<Scalars['String']>;
  aiSummary?: Maybe<Scalars['String']>;
  aiTags?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idRaw: Scalars['String'];
  joinedAt?: Maybe<Scalars['Date']>;
  languageCode?: Maybe<Scalars['String']>;
  leftAt?: Maybe<Scalars['Date']>;
  messagesSent?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  reactionsSent?: Maybe<Scalars['Int']>;
  stage?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type StatsNode = {
  __typename?: 'StatsNode';
  chatMembers: Scalars['Int'];
  landingClicks: Scalars['Int'];
  landingViews: Scalars['Int'];
  profiles: Scalars['Int'];
  referrers: Array<ReferrerNode>;
  stagesActivated: Scalars['Int'];
  stagesActivatedPeriod: Scalars['Float'];
  stagesEngaged: Scalars['Int'];
  stagesEngagedPeriod: Scalars['Float'];
  stagesLost: Scalars['Int'];
  stagesLostPeriod: Scalars['Float'];
  stagesNotActivated: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  currency: Scalars['String'];
  datetime: Scalars['DateTime'];
  status: Scalars['String'];
  total: Scalars['Int'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  createdAt: Scalars['DateTime'];
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<StripeInvoice>>;
  price: Scalars['Int'];
  status: Scalars['String'];
};

/** An enumeration. */
export enum SubscriptionTypeEnum {
  /** Free */
  Free = 'FREE',
  /** Paid */
  Paid = 'PAID'
}

export type SummaryApplicationsNode = {
  __typename?: 'SummaryApplicationsNode';
  applications: Scalars['Int'];
  applicationsChange: Scalars['Int'];
};

export type SummaryLikesNode = {
  __typename?: 'SummaryLikesNode';
  likes: Scalars['Int'];
  likesChange: Scalars['Int'];
};

export type SummaryMembersLeftNode = {
  __typename?: 'SummaryMembersLeftNode';
  intervalMembersLeft: Scalars['Int'];
  totalMembersLeft: Scalars['Int'];
};

export type SummaryMembersNode = {
  __typename?: 'SummaryMembersNode';
  members: Scalars['Int'];
  membersChange: Scalars['Int'];
};

export type SummaryMessagesNode = {
  __typename?: 'SummaryMessagesNode';
  messages: Scalars['Int'];
  messagesChange: Scalars['Int'];
};

export type TagInput = {
  name: Scalars['String'];
};

export type TagNode = {
  __typename?: 'TagNode';
  id: Scalars['ID'];
  isHobby: Scalars['Boolean'];
  name: Scalars['String'];
  usersCount?: Maybe<Scalars['Int']>;
};

export type TelegramAuthMutation = {
  __typename?: 'TelegramAuthMutation';
  user?: Maybe<UserNode>;
};

export type TelegramConfiguration = {
  __typename?: 'TelegramConfiguration';
  authUrl?: Maybe<Scalars['String']>;
  botHandle?: Maybe<Scalars['String']>;
};

export enum TimeframeEnum {
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year'
}

export type TopMemberConnection = {
  __typename?: 'TopMemberConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TopMemberEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TopMember` and its cursor. */
export type TopMemberEdge = {
  __typename?: 'TopMemberEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TopMemberNode>;
};

export type TopMemberNode = {
  __typename?: 'TopMemberNode';
  image?: Maybe<Scalars['String']>;
  link?: Maybe<MemberLinkNode>;
  messages: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  rawId: Scalars['String'];
  user?: Maybe<UserNode>;
};

export type TopMessageNode = {
  __typename?: 'TopMessageNode';
  rawMessage?: Maybe<Scalars['String']>;
  rawMessageId: Scalars['String'];
  reactionCount: Scalars['Int'];
};

export type TopReactionNode = {
  __typename?: 'TopReactionNode';
  count: Scalars['Int'];
  reaction: Scalars['String'];
};

export type UniversityInput = {
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UniversityNode = {
  __typename?: 'UniversityNode';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateBaseUserMutation = {
  __typename?: 'UpdateBaseUserMutation';
  user?: Maybe<UserNode>;
};

export type UpdateEducationMutation = {
  __typename?: 'UpdateEducationMutation';
  education?: Maybe<EducationNode>;
};

export type UpdateExperienceMutation = {
  __typename?: 'UpdateExperienceMutation';
  experience?: Maybe<CompanyExperienceNode>;
};

export type UpdatePhoneNumberMutation = {
  __typename?: 'UpdatePhoneNumberMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UserFilters = {
  cityIds: Array<Scalars['Int']>;
  companySize?: InputMaybe<CompaniesCompanySizeChoices>;
  directionGroupIds: Array<Scalars['Int']>;
  jobTitleGroupIds: Array<Scalars['Int']>;
  prompt?: InputMaybe<Scalars['String']>;
};

export type UserHarvInput = {
  about?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyExperiences?: InputMaybe<Array<InputMaybe<CompanyExperienceInput>>>;
  education?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  featured?: InputMaybe<Array<InputMaybe<FeaturedInput>>>;
  headline?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  linkLinkedin?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type UserLocationMutation = {
  __typename?: 'UserLocationMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UserNode = {
  __typename?: 'UserNode';
  about?: Maybe<Scalars['String']>;
  city?: Maybe<CityNode>;
  communityMember?: Maybe<CommunityMemberNode>;
  communityMembers?: Maybe<Array<CommunityMemberNode>>;
  companyExperience?: Maybe<Array<CompanyExperienceNode>>;
  /** Level of connection with current user */
  connection?: Maybe<Connection>;
  education?: Maybe<Array<EducationNode>>;
  email?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<FeaturedNode>>;
  firstName: Scalars['String'];
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  inviteJoined: Scalars['Boolean'];
  inviteStatus: UsersYkmeUserInviteStatusChoices;
  isLinkedinError: Scalars['Boolean'];
  isTelegramConnected: Scalars['Boolean'];
  isWhatsappConnected: Scalars['Boolean'];
  lastName: Scalars['String'];
  linkBehance?: Maybe<Scalars['String']>;
  linkCalendly?: Maybe<Scalars['String']>;
  linkFacebook?: Maybe<Scalars['String']>;
  linkGithub?: Maybe<Scalars['String']>;
  linkInstagram?: Maybe<Scalars['String']>;
  linkLinkedin?: Maybe<Scalars['String']>;
  linkTelegram?: Maybe<Scalars['String']>;
  linkTiktok?: Maybe<Scalars['String']>;
  linkTwitter?: Maybe<Scalars['String']>;
  linkYoutube?: Maybe<Scalars['String']>;
  needHelp?: Maybe<CurrentHelpNode>;
  onboardingFinished: Scalars['Boolean'];
  profileStatus?: Maybe<UsersYkmeUserProfileStatusChoices>;
  tags: Array<TagNode>;
  /** Ready to parse into from linkedin */
  toParse: Scalars['Boolean'];
  workedWith?: Maybe<Array<WorkedWithNode>>;
};

export type UserSocialMutation = {
  __typename?: 'UserSocialMutation';
  user?: Maybe<UserNode>;
};

export type UsersHarvMutation = {
  __typename?: 'UsersHarvMutation';
  id?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum UsersYkmeUserInviteStatusChoices {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Created */
  Created = 'CREATED',
  /** Pending */
  Pending = 'PENDING'
}

/** An enumeration. */
export enum UsersYkmeUserProfileStatusChoices {
  /** Has AI Profile */
  AiProfile = 'AI_PROFILE',
  /** Has LinkedIn Profile */
  LinkedinProfile = 'LINKEDIN_PROFILE'
}

export type WhatsappConnectConfirmMutation = {
  __typename?: 'WhatsappConnectConfirmMutation';
  connected?: Maybe<Scalars['Boolean']>;
};

export type WhatsappConnectMutation = {
  __typename?: 'WhatsappConnectMutation';
  manuallyRequired?: Maybe<Scalars['Boolean']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type WorkedWithConfirmMutation = {
  __typename?: 'WorkedWithConfirmMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type WorkedWithMutation = {
  __typename?: 'WorkedWithMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type WorkedWithNode = {
  __typename?: 'WorkedWithNode';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type WorkedWithRequestNode = {
  __typename?: 'WorkedWithRequestNode';
  confirmed: ConfirmedEnum;
  createdAt: Scalars['DateTime'];
  fromUser: UserNode;
  id: Scalars['ID'];
};
