'use client';

import React, { FC, useContext } from 'react';
import NextImage from 'next/image';
import Link from 'next/link';

import { useMedia } from 'react-use';

import { Button } from 'components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from 'components/ui/sheet';
import { BurgerIcon, CloseIcon } from 'components/icons/v2';

import LandingContext, { ModalTabs } from 'app/_context/Landing';
import { ILandingConfig } from 'types';

interface IHeaderProps {
  logo?: string | null;
  communityName: string;
  appConfig: ILandingConfig;
  headerButtonTitle?: string | null;
  headerSecondaryButtonLink?: string | null;
  headerSecondaryButtonTitle?: string | null;
}

const MobileMenu: FC<IHeaderProps> = (props) => {
  const {
    appConfig,
    communityName,
    logo,
    headerButtonTitle,
    headerSecondaryButtonLink,
    headerSecondaryButtonTitle
  } = props;

  const { setTab } = useContext(LandingContext);

  return (
    <Sheet>
      <SheetTrigger>
        <BurgerIcon />
      </SheetTrigger>
      <SheetContent side="top" className="bg-white p-4">
        <SheetHeader className="flex-row justify-between">
          <div className="flex gap-2 items-center">
            {!!logo && (
              <NextImage src={logo} width={41} height={41} alt="Community-logo" />
            )}
            <p className="font-montserrat text-display-xs font-semibold lg:text-[16px]/[20px]">{communityName}</p>
          </div>
          <Button variant="nakedGrayLink" className="p-0 focus-visible:outline-none" asChild>
            <SheetTrigger>
              <CloseIcon />
            </SheetTrigger>
          </Button>
        </SheetHeader>
        <div className="py-6 flex flex-col">
          {!!(headerSecondaryButtonLink && headerSecondaryButtonTitle) ? (
            <Button variant="nakedBrandLink" size="large" asChild>
              <Link href={headerSecondaryButtonLink} target="_blank">
                {headerSecondaryButtonTitle}
              </Link>
            </Button>
          ) : (
            <Button variant="nakedGrayLink" size="large" onClick={() => setTab(() => ModalTabs.LOGIN)}>Sign in</Button>
          )}
          {/* button hidden for now */}
          {/*<Button variant={appConfig.buttonVariant} size="large"*/}
          {/*        onClick={() => setTab(() => ModalTabs.APPLY)}>*/}
          {/*  {headerButtonTitle ? headerButtonTitle : "Join"}*/}
          {/*</Button>*/}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export const Header: FC<IHeaderProps> = (props) => {
  const {
    communityName,
    logo,
    appConfig,
    headerButtonTitle,
    headerSecondaryButtonLink,
    headerSecondaryButtonTitle
  } = props;

  const isLaptop = useMedia('(min-width: 768px)', true);

  const { setTab } = useContext(LandingContext);

  return (
    <header className="flex px-[115px] py-4 justify-between lg:px-4">
      <div className="flex gap-2 items-center">
        {!!logo && (
          <NextImage src={logo} width={41} height={41} alt="Community-logo" />
        )}
        <p className="font-montserrat text-display-xs font-semibold lg:text-[16px]/[20px]">{communityName}</p>
      </div>
      <div className="flex gap-1">
        {!isLaptop ? (
          <MobileMenu
            appConfig={appConfig}
            communityName={communityName}
            logo={logo}
            headerButtonTitle={headerButtonTitle}
            headerSecondaryButtonLink={headerSecondaryButtonLink}
            headerSecondaryButtonTitle={headerSecondaryButtonTitle}
          />
        ) : (
          <>
            {/* button hidden for now */}
            {/*<Button*/}
            {/*  variant={appConfig.buttonVariant} size="large"*/}
            {/*  onClick={() => setTab(() => ModalTabs.APPLY)}*/}
            {/*>*/}
            {/*  {headerButtonTitle ? headerButtonTitle : 'Join'}*/}
            {/*</Button>*/}
            {!!(headerSecondaryButtonLink && headerSecondaryButtonTitle) ? (
              <Button variant="nakedGrayLink" size="large" asChild className="mr-4">
                <Link href={headerSecondaryButtonLink} target="_blank">
                  {headerSecondaryButtonTitle}
                </Link>
              </Button>
            ) : (
              <Button variant="nakedGrayLink" size="large" onClick={() => setTab(() => ModalTabs.LOGIN)}>Sign in</Button>
            )}
          </>
        )}
      </div>
    </header>
  );
};
