import React, { useCallback, useId } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'lib/utils';
import { Button } from './button';

const inputVariants = cva(
  '',
  {
    variants: {
      variant: {
        default: 'flex-1 focus:outline-none text-medium text-gray-900 disabled:bg-gray-50 disabled:text-gray-200 disabled:cursor-not-allowed',
        old: 'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  label?: string;
  hintText?: string;
  errorText?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  additionalContentLeft?: React.ReactNode;
  additionalRightButton?: React.ReactNode;
  additionalRightButtonCb?: () => void;
  containerClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      label,
      hintText,
      errorText,
      variant,
      className,
      type,
      leftIcon,
      rightIcon,
      additionalRightButtonCb,
      additionalRightButton,
      additionalContentLeft,
      containerClassName,
      ...rest
    } = props;

    const getBorderStyles = useCallback((
      isLeftElementExist: boolean,
      isRightElementExist: boolean,
    ) => {
      if (isLeftElementExist && isRightElementExist) {
        return 'rounded-none';
      } else if (isLeftElementExist) {
        return 'rounded-radius-xl rounded-l-none';
      } else if (isRightElementExist) {
        return 'rounded-radius-xl rounded-r-none';
      }
      return 'rounded-radius-xl';
    }, []);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      additionalRightButtonCb?.();
    }, [additionalRightButtonCb]);

    const id = useId();

    return variant === 'old' ? (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...rest}
      />
    ) : (
      <div className="flex flex-col gap-sm flex-1">
        {!!label && (
          <label
            className={`${rest.required && `after:content-['*'] after:absolute after:left-[calc(100%+5px)] after:text-brand-600`} font-medium text-small max-w-max flex text-gray-900 relative`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div className="flex">
          {!!additionalContentLeft && (
            <div className={`content-center p-[9px_14px] border border-gray-200 border-r-0 text-medium rounded-l-radius-xl ${rest.disabled ? 'bg-gray-50 text-gray-200' : 'bg-transparent text-gray-600'}`}>
              {additionalContentLeft}
            </div>
          )}
          <div
            className={`flex flex-1 border items-center gap-md shadow-xs ${getBorderStyles(!!additionalContentLeft, !!additionalRightButton)} overflow-hidden p-[9px_14px] ${!!errorText ? 'border-error-500' : 'border-gray-200'} ${rest.disabled ? 'bg-gray-50 text-gray-200' : 'bg-transparent'} ${containerClassName ?? ''}`}>
            {!!leftIcon && leftIcon}
            <input
              id={id}
              type={type}
              className={cn(inputVariants({ variant, className }))}
              ref={ref}
              {...rest}
            />
            {!!rightIcon && <div className={errorText ? 'text-error-500' : ''}>{rightIcon}</div>}
          </div>
          {!!additionalRightButton && (
            <div className="overflow-hidden rounded-r-radius-xl border border-l-0 border-gray-200">
              <Button
                size="medium"
                variant="nakedGrayLink"
                className="flex items-center justify-center rounded-none"
                onClick={handleClick}
              >
                {additionalRightButton}
              </Button>
            </div>
          )}
        </div>
        {!!(errorText || hintText) && (
          <p
            className={`text-small ${!!errorText ? 'text-error-600' : 'text-gray-600'}`}>{errorText ? errorText : hintText}</p>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
